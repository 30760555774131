import { graphql } from "gatsby"
import React from "react"
import { Container } from "react-grid-system"
import Breadcrumbs from "../components/breadcrumbs"
import CategoriesList from "../components/categoriesList"
import SEO from "../components/seo"
import { H1 } from "../components/UI/typography"

export default ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs }
  } = pageContext
  return (
    <Container>
      <SEO title="Каталог" />
      <Breadcrumbs crumbs={crumbs} label={"Каталог"} />
      <H1>Каталог</H1>
      <CategoriesList
        categories={data.allWcProductsCategories.edges.map(({ node }) => ({
          key: node.id,
          name: node.name,
          href: node.slug,
          quantity: node.count,
          image: node.image
            ? {
                alt: node.image.alt,
                fixed: node.image.localFile.childImageSharp.fixed,
              }
            : null,
        }))}
      />
    </Container>
  )
}

export const query = graphql`
  {
    allWcProductsCategories(sort: { order: ASC, fields: menu_order }) {
      edges {
        node {
          name
          id
          slug
          count
          image {
            alt
            localFile {
              childImageSharp {
                fixed(width: 128, toFormat: WEBP) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          menu_order
        }
      }
    }
  }
`
