import Loadable from "@loadable/component"
import React from "react"
import CategoryLink from "./categoryLink"
const Grid = Loadable(() => import("./UI/grid"))

const CategoriesList = ({ categories }) => (
  <Grid data={categories}>
    <CategoryLink />
  </Grid>
)

export default CategoriesList
