import styled from "@emotion/styled"
import Img from "gatsby-image"
import React from "react"
import StretchedLink from "../utils/stretchedLink"
import { borderRadius, white } from "../utils/variables"
import { gradient } from "./UI/gradient"
import { H2 } from "./UI/typography"

const CategoryLink = ({ data }) => {
  const { name, quantity, href, image } = data
  return (
    <Wrapper>
      <div>
        <Heading>{name}</Heading>
        <Quantity>{quantity} позиции</Quantity>
      </div>
      <StretchedLink to={`/catalog/${href}`} title={name}></StretchedLink>
      {image && (
        <ImageWrapper>
          <Img fixed={image.fixed} alt={image.alt} />
        </ImageWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 24px;
  ${gradient};
  border-radius: ${borderRadius};
`

const Heading = styled(props => <H2 {...props} />)`
  color: ${white};
  margin-top: 12px;
`

const Quantity = styled.span`
  font-size: 20px;
  font-family: "Open Sans Regular";
  color: ${white};
`
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 22px;
`

export default CategoryLink
